<!--
 * @Description: 上传文件
 * @Author: xiawenlong
 * @Date: 2020-12-19 14:23:46
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-07-01 15:09:01
-->
<template>
  <div class="upload-file">
    <el-upload
      class="uploader-file"
      action=""
      :file-list="fileList"
      :limit="1"
      drag
      multiple
      :http-request="uploadRequest"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      :before-upload="beforeAvatarUpload"
      accept=".pdf,.jpg,.jpeg,.png"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <p class="tip" style="line-height:6px">支持扩展名：.jpg .png .jpeg .pdf</p>
    </el-upload>
  </div>
</template>
<script>
import { getFileUpload } from '@/api/college'
import to from 'await-to'
export default {
  name: 'UploadImage',
  model: {
    prop: 'value',
    event: 'setModelVal',
  },
  props: {
    value: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      fileList: [],
    }
  },
  watch: {
    value(val) {
      if (val.length) {
        this.fileList = val.map(item => ({ ...item, name: item.docName }))
      }
    },
  },
  methods: {
    async uploadRequest(file) {
      const params = new FormData()
      params.append('fileTypeCode', 'F002')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(getFileUpload(params))
      if (err) {
        this.fileList = []
        return this.$message.error(err.msg)
      }
      this.$emit('setModelVal', [
        ...this.fileList,
        {
          name: file.file.name,
          docName: file.file.name,
          url: res.data,
        },
      ])
    },
    beforeAvatarUpload(file) {
      var filetype = file.type
      var IsFile = filetype.split('/')[1]
      if (IsFile == 'pdf' || IsFile == 'jpg' || IsFile == 'png' || IsFile == 'jpeg') {
        console.log('符合格式')
      } else {
        this.$message.error('上传文件类型必须是pdf、png、jpg、jgeg格式文件!')
        return false
      }
    },
    handlePreview(file) {
      const href = file.url
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.download = file.docName
      a.click()
      URL.revokeObjectURL(a.href)
    },
    handleRemove(file, fileList) {
      this.$emit('setModelVal', [])
      this.fileList = fileList
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-file {
  ::v-deep.el-upload-list {
    width: 360px;
  }
}
</style>
